import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';


export default function erpclick() {
  return (
    
    <BasicLayout>
           <Helmet>
                <meta charSet="utf-8" />
                <title>ERP Click</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/erpclick" />
                <meta name="description" content="Gestiona integramente tu negocio con el ERP Click. Miles de funcionalidades y la opción de personalizar tu herramienta para la eficaz gestión de tu empresa."></meta>
            </Helmet>
      <Container fluid>

        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >ERP click</h1>
          </Col>
        </Row>
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center'>
              <Image src='./images/top-view.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>Gestión de empresas: facturación, contabilidad y mucho más.</h3>
            <p>Los ERPs de Click se caracterizan por su robustez y multitud de funcionalidades. Con más de 20 años de experiencia en el sector de la alimentación, Click se ha consolidado como un referente en el desarrollo y evolución de herramientas para la gestión empresarial.</p>
            <p>Desde el ERP, es posible centralizar toda la actividad económica de la empresa, incluyendo pagos, compras, ventas, almacenes, facturas, albaranes, tributación, pedidos, generación de informes, etc.</p>
            <p>Su intuitiva interfaz suaviza de sobremanera la curva de aprendizaje, y el equipo de soporte técnico se encuentra a tu disposición cuando tengas algún problema que no puedas resolver.</p>
          </Col>
        </Row>

        <Row>
          <Col className='text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 className='my-4'>"La gestión integral de tu empresa es más fácil de lo que habías soñado"</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>Arquitectura de la herramienta</h3>
            <p>El ERP de Click es una aplicación para escritorio con arquitectura cliente/servidor y con una base de datos en tiempo real. Con cada llamada a la base de datos se guardan los últimos cambios realizados por lo que es casi imposible perder datos por la interrupción del servicio eléctrico.</p>
            <p>Además, la información se encripta cada noche para ser almacenada como copia de seguridad en varios servidores ubicados en distintas regiones geográficas.</p>
            <p>El protocolo de transferencia no de archivos no utiliza los conocidos puertos 80 y 443 comúnmente usados por HTTP y HTTPs, sino que  se emplea el puerto 690 añadiendo una capa adicional a la seguridad.</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image src='./images/personas-oficinas.webp' fluid/>
            </div>
          </Col>
        </Row>
      </Container>
    </BasicLayout>
  );
}
             