import React from 'react';
import "./Footer.scss";
import { Container,Row,Col,Image } from "react-bootstrap";



export default function Footer() {
  return (
        <Container fluid className='p-0'>
            <Row className='footerBody m-0 p-0'>
                <div className='hrClass my-5'></div>
                <Col>
                    <Container>
                        <Row>
                            <Col xs={12} md={4} className='text-center'>
                                <Image src='../../images/logo_click_negro.png' style={{width:'150px'}} fluid/>
                            </Col>
                            <Col xs={12} md={4} className='text-white'>
                                <p>Desarrollamos soluciones de software innovadoras que mejoran la eficiencia y productividad de nuestros clientes, impulsando la transformaicón y la digitalización de sus negocios.</p>
                                <p>Av.Olímpica, 34 locales 4 y 6, 28935. Móstoles(Madrid).</p>
                                <p>Tel. 606 84 49 03 info@clickaplicaciones.es.</p>
                            </Col>
                            <Col xs={12} md={4}>
                                <ul className='separador' style={{listStyle:'none',paddingLeft:'10px'}}>
                                    <li className='mb-2'><a className='hiperV' href="/about"><strong>Nuestro equipo</strong></a></li>
                                    <li className='mb-2'><a className='hiperV' href="/appweb"><strong>Desarrollo de software</strong></a></li>
                                    <li className='mb-2'><a className='hiperV' href="/adapta-negocio-normativa"><strong>Ciberseguridad</strong></a></li>
                                    <li className='mb-2'><a className='hiperV' href="/centrix"><strong>Casos de éxito</strong></a></li>
                                    <li className='mb-2'><a className='hiperV' href="/blog"><strong>Blog</strong></a></li>
                                    <li className='mb-2'><a className='hiperV'  href="/contact"><strong>Contacto</strong></a></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className='mt-0 mb-5'>
                            <div className='col-12 offset-md-8 col-md-4 mt-0'>
                                <a target='_blank' href="https://x.com/ClickAplicacion?t=XQavf3J03uUXvpE_orcrPA&s=09"><Image className='socialNet' src="../../images/twitter.png" fluid style={{width:'25px',marginRight:'20px'}}/></a>
                                <a target='_blank' href="https://www.instagram.com/clickaplicaciones/"><Image className='socialNet' src="../../images/insta.png" fluid style={{width:'25px',marginRight:'20px'}}/></a>
                                <a target='_blank' href="https://www.youtube.com/@clickaplicaciones"><Image className='socialNet' src="../../images/linke.png" fluid style={{width:'25px',marginRight:'20px'}}/></a>
                                <a target='_blank' href="https://es.linkedin.com/company/click-aplicaciones-inform%C3%A1ticas-s.l."><Image className='socialNet' src="../../images/youtube.png" fluid style={{width:'25px'}}/></a>
                            </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className='bottomBar m-0 p-0'>
                <Col>
                <Container>
                    <Row style={{height:'auto'}}>
                        <Col xs={2}>
                            <span>@Click</span>
                        </Col>
                        <Col xs={10} className='text-end'>
                            <span><a href="/aviso-legal" target='_blank' className='text-white' style={{textDecoration:'none'}}>Aviso Legal</a> | <a href="/politica-privacidad" target='_blank' className='text-white' style={{textDecoration:'none'}}>Privacidad</a> | <a href="/Aviso-legal" className='text-white' style={{textDecoration:'none'}}>Cookies</a></span>
                        </Col>
                    </Row>
                </Container>
                </Col>
            </Row>
        </Container>
  )
}
