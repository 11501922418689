import React from 'react';
import "./contactLan.scss";
import { Image } from "react-bootstrap";
import Contactform from '../../Contactform/contactform';


export default function ContactLan() {
  return (
    <div className='container-fluid'>
      <div className='container p-0 contact'>
        <div className='row my-5 p-0'>
            <div className='col-12 col-md-6 p-md-3 p-lg-5'>
                <h2>Resuelve tus dudas, contacta con nosotros</h2>
                <p>Si tienes dudas nuestros especialistas pueden ayudarte. Recibirás el asesoramiento que necesitas de forma gratuita y sin compromiso.</p>
                <Contactform/>
            </div>
            <div className='col-12 col-md-6 p-md-3 p-lg-5'>
                <Image className='d-none d-md-block' src='./images/soporte.jpg' style={{width:'550px',float:'right',marginTop:'100px'}} fluid/>
            </div>
        </div>
      </div>
    </div>
   
  )
}
