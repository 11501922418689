import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./about.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';



export default function Mision() {
  return (
    <BasicLayout>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Misión, visión y valores</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/mision" />
                <meta name="description" content="Click asume una serie de compromisos y valores con el fin de construir una sociedad mejor y mas justa."></meta>
            </Helmet>
        <Container>
            <Row>
                <Col>
                    <h1 className='my-5' style={{color:'#131a24'}}>Misión, visión y valores de Click</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className='mt-4'>
                    <h3>El trato familiar de una empresa pequeña, y la eficacia de un equipo top de profesionales.</h3>
                    <p>Las oficinas de Click son el centro neurálgico de la actividad empresarial que se desarrolla cada día. Un lugar lleno de vida que a nuestros clientes y proveedores les encanta visitar por el trato cercano y respetuoso que se les brinda.</p>
                    <p>El principal objetivo de Click es la tranquilidad y la satisfacción de todos sus clientes. Se les ayuda a encontrar la solución adecuada al problema que presentan y el incansable equipo de soporte se ocupa de que sus sistemas se mantengan en correcto funcionamiento cada día.</p>
                </Col>
                <Col xs={12} className='mt-4'>
                    <h3>Preocupación por el medio ambiente.</h3>
                    <p>Una de las mejores formas de cuidar el medio ambiente es proteger los bosques de la tala masiva de árboles. Click, como empresa tecnológica, sabe que el proceso de digitalización dentro del tejido productivo español aún no se ha completado.</p>
                    <p>Por eso cuando se lleva a cabo el desarrollo de una aplicación, se hace siempre teniendo en cuenta una potente gestión documental para evitar el uso indiscriminado del papel. Si conseguimos cambiar folios de papel por archivos pdf o doc, nuestro planeta saldrá ganando y contribuimos a un medio mejor y más sostenible.</p>
                    <p>Además, Click acepta el reto de recducir la huella de carbono, llevando a cabo las políticas e implementando las tecnologías necesarias para lograrlo.</p>
                </Col>
                <Col xs={12} className='mt-4'>
                    <h3>Poner el foco en el crecimiento personal de empleados.</h3>
                    <p>La mejor forma de crecer como empresa es sus empleados crezcan a nivel personal y profesional. Click se involucra en que su equipo de trabajo mantenga un trato cordial y distendido, como base para formar una maquina bien engrasada con la que afrontar los retos futuros.</p>
                    <p>La formación del personal también es algo a tener muy en cuenta. Las PYMES, por regla general, no disponen de plantillas muy extensas y necesitan que sus trabajadores tengan una base de todo el ciclo productivo, con independencia de que cada uno además, cuente con su especialidad propia. Para conseguir este objetivo, es necesario democratizar el conocimiento y reglamentar una serie de sesiones de formación en aras a acelerar el aprendizaje de ciertas materias.</p>
                </Col>
                <Col xs={12} className='mt-4'>
                    <h3>La información es el mayor de los tesoros.</h3>
                    <p>En los tiempos que vivimos, la ciberdelincuencia se ha convertido en una actividad a temer por empresas y organizaciones. El robo de datos para venderlos o extorsionar a sus titulares puede suponer un  verdadero desastre. Por ello es necesario comprometerse con las más actuales políticas en seguridad de la información.</p>
                    <p>Desde hace varios años, Click lleva realizando auditorías internas a sus sistemas y redes con caracter anual. En la actulidad, la empresa se encuentra inmersa en la implementación de la normativa ISO 27001, para posteriomente obtener su correspondiente certificación.</p>
                    <p>Por si fuera poco, todo el personal de Click, los miembros del equipo de desarrollo, pero también el personal administrativo y de dirección han sido formados con nociones básicas en ciberseguridad y conocen tanto las medidas físicas como lógicas que deben aplicar para hacer de empresa una zona segura para el tratamiento de datos.</p>
                </Col>
                <Col xs={12} className='mt-4'>
                    <p className='text-end my-4'>Última actualización: 01/09/2024</p>
                </Col>
            </Row>
            <Row>
                <Col className='col-12 col-md-12 mt-3 mb-5'>
                    <div className='text-center'>
                        <Image className='imgsize' src='../../images/mision.webp' fluid/>
                    </div>
                </Col>
            </Row>
        </Container>
    </BasicLayout>
  );
}