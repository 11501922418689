import React,{useState} from 'react';
import { Container,Row,Col } from "react-bootstrap";
import { Formik } from 'formik';
import axios from 'axios';
import { SpinnerRoundOutlined } from 'spinners-react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useEffect } from 'react';




export default function Cvform(props) {
var loading;
  const [file, setFieldValue] = useState();
  const [state, setState] = useState({});
  const [isLoading, setLoading] = useState();
  const [isAlert, setAlerting] = useState('');
  const tipo = props.tipo;
  const label = { inputProps: { 'aria-label': 'Aceptas la política de privacidad de la empresa' } };


  

  const [provincias,setProvincias]  = useState([]);
  const [oculta,setOculta]  = useState(false);

  const getProvincias = () =>{
      return axios.get('https://cloud-111.clickaplicaciones.es/integgra_backend/getprovincesandcountries')
      .then(response=>{
        setProvincias(response.data.provinces);
      });
  }
  useEffect(()=>{
      getProvincias();
      //getGeoloc();
  },[]);



  return(
  <>
  {isLoading ? <Container style={{height:'50vh'}}><div className='row justify-content-center'><SpinnerRoundOutlined size={78} color="rgba(0, 212, 72, 1)" style={{marginTop:'150px'}}/><p className='text-center'>Enviando formulario</p></div></Container> : 
           
           <>
           
<Formik
initialValues={{ name:'',email:'',phone: '',town:'',province:'',priv:false }}
validate={values => {
  const errors = {};


  if (!values.name) {
    errors.name = 'El nombre es requerido';
  } else if (
    !/^[a-zA-Z0-9 ]{6,70}$/i.test(values.name)
  ) {
    errors.name = 'Formato de nombre no válido';
  }

  if (!values.town) {
    errors.town = 'El municipio es requerido';
  } else if (
    !/^[a-zA-ZÀ-ÿ0-9 ()-]{6,70}$/i.test(values.town)
    
  ) {
    errors.town = 'Formato de municipio no válido';
  }

  if (!values.province) {
    errors.province = 'La provincia es requerida';
  } 
  


  if (!values.email) {
    errors.email = 'El email es requerido';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,70}$/i.test(values.email)
  ) {
    errors.email = 'Dirección email no válida';
  }

  if (!values.phone) {
    errors.phone = 'El teléfono es requerido';
  } else if (
    !/^([0-9]){6,13}$/i.test(values.phone)
  ) {
    errors.phone = 'El número de teléfono no es válido';
  }
 

  if (values.priv==false) {
    errors.priv = 'Es necesario aceptar la política de privacidad';
  }


  console.log(errors)
  return errors;
}}
onSubmit={(values, { setSubmitting }) => {
  console.log(state)
  setLoading(true);
  loading = true;

  if(!state.file){
    console.log('Debe subir una imagen de la vivienda'); 
    setLoading(false);
    setAlerting('errorImagen');
    setTimeout(()=>{
      setAlerting('');
    },2000);
    return;
  }
  
  values['file'] = state.file;

 


  
  console.log(values);
  const formData = new FormData();
  formData.append('file',state.file);
  formData.append('values',JSON.stringify(values))
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }
  console.log(state);
  console.log(formData.values);
  return  axios.post("https://cloud-111.clickaplicaciones.es/clickaplicaciones/curriculum", formData,config).then(response=>{
    console.log(response);

    setTimeout(function(){
      setLoading(false);
    },3000);
    setAlerting('success');
    setTimeout(()=>{
      setAlerting('')
    },2000);
  }).catch(()=>{
    setAlerting('error');
    setLoading(false);
    setTimeout(()=>{
      setAlerting('')
    },2000);
  });


}}
>
{({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit}>

        <Row className='mb-4'>

          <Col xs={12} md={6}>
          <FormControl fullWidth>
              <TextField id="fullname" label="Nombre y apellidos" variant="outlined" name="name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} />
              <span style={{color:'red'}}>{errors.name && touched.name && errors.name}</span>
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='mb-4'>
              <FormControl fullWidth>
                  <InputLabel htmlFor={"province"} id="demo-simple-select-label">Provincia</InputLabel>
                  <Select variant="outlined"
                    labelId="demo-simple-select-label" id="province" name="province" label="Provincia" onChange={handleChange} onBlur={handleBlur}>
                    {
                      provincias?.map((item)=>{
                        return(
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                  <span style={{color:'red'}}>{errors.province && touched.province && errors.province}</span>
              </FormControl>
          </Col>
          <Col xs={12} md={4} className='mb-4'>
              <FormControl fullWidth>
                <TextField id="town" label="Municipio" variant="outlined" name="town" type="text" onChange={handleChange} onBlur={handleBlur} value={values.town} />
                <span style={{color:'red'}}>{errors.town && touched.town && errors.town}</span>
              </FormControl>
          </Col>
          <Col xs={12} md={4} className='mb-4'>
              <FormControl fullWidth>
                <TextField id="email" label="Correo electrónico" variant="outlined" name="email" type="text" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                <span style={{color:'red'}}>{errors.email && touched.email && errors.email}</span>
              </FormControl>
          </Col>
          <Col xs={12} md={4} className='mb-4'>
              <FormControl fullWidth>
                <TextField id="phone" label="Teléfono" variant="outlined" name="phone" type="number" onChange={handleChange} onBlur={handleBlur} value={values.phone} />
                <span style={{color:'red'}}>{errors.phone && touched.phone && errors.phone}</span>
              </FormControl>
          </Col>



        </Row>

        <Row className='mb-3'>
          <Col xs={12}>
            <FormControl fullWidth className='mb-3'>
              <TextField id="file" variant="outlined" name="file" type="file" onChange={(event) => {setState({"file": event.currentTarget.files[0]});}}/>
              <span style={{color:'red'}}>{errors.file && touched.file && errors.file}</span>
            </FormControl>
           

          </Col>
        </Row>
        
        <Row>
          <Col xs={12}>
            <Checkbox onChange={handleChange} onBlur={handleBlur} {...label} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} name="priv"/> Aceptas la política de privacidad de la empresa  
            <span style={{color:'red',display:'block'}}>{errors.priv && touched.priv && errors.priv}</span>
          </Col>
        </Row>

   
    <Row className='justify-content-center mb-5'>
      <Button style={{width:'150px'}} className='botonPrimario' type="submit" variant="contained">Enviar</Button>
    </Row>
</form>
)}
</Formik></>}
  <Row>
      <Col>
          <div className='info'>
              <p className='text-center'>Responsable de los datos: Click Aplicaciones Informáticas S.L.</p>
              <p className='text-center'>Finalidad: Ofrecer al usuario la posibilidad de acceder a los procesos de selección que se deriven de la necesidad de cubrir vacante laboral.</p>
              <p className='text-center'>Legitimación: Consentimiento del interesado.</p>
              <p className='text-center'>Destinatarios: Encargados del tratamiento de tus datos: Click Aplicaciones S.L.</p>
              <p className='text-center'>Derechos: Acceder, rectificar y suprimir los datos, y otros derechos, como se explica en nuestra política de privacidad.</p>
          </div>
      </Col>
  </Row>
  {
            
            (isAlert=='success') ? 
                                        <Alert style={{position:'fixed',top:'100px',right:'100px'}} severity="success">
                                            Has subido tu cv correctamente.
                                        </Alert>
                                 : <></>
            }
            {
            (isAlert=='error') ?
            
                <Alert style={{position:'fixed',top:'100px',right:'100px'}} severity="error">
                    Algo salió mal. Inténtelo de nuevo o contacte con el administrador del sitio.
                </Alert>
               
            : <></>
            }
            {
            (isAlert=='errorImagen') ?
            
                <Alert style={{position:'fixed',top:'100px',right:'100px'}} severity="error">
                    Debes subir un archivo.
                </Alert>
               
            : <></>
            }
             
</>
  
  )

}
