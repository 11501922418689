import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';

export default function AvisoLegal() {
  return (
    <>
      <BasicLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Aviso legal</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/aviso-legal" />
                <meta name="description" content=""></meta>
                <meta name="robots" content="noindex,nofollow"></meta>
            </Helmet>
          <Container>
            <Row>
                <Col className='mt-5'>
                    <h3>Datos</h3>
                    <p>Denominación Social: Click Aplicaciones Informáticas S.L.</p>
                    <p>Domicilio Social:Avenida Olímpica 34, locales 2 y 4 28935 Móstoles, Madrid (España)</p>
                    <p>CIF: B-85026433</p>
                    <p>Teléfono: +34-606844903</p>
                    <p>E-Mail: info@clickaplicaciones.es</p>
                    <p>Web: www.clickaplicaciones.com</p>
                    <p>El presente aviso e información legales (en adelante, el “Aviso Legal”) regula el uso de los servicios de Internet de esta página web, propiedad de Click Aplicaciones Informáticas S.L. (en adelante el “Sitio Web y Aplicaciones móviles”) que  se pone a disposición de los usuarios, a los que se facilita el acceso y la utilización de diversos servicios y contenidos.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Condiciones de uso y aceptación</h3>
                    <p>Por la mera utilización del Sitio web Usted adquiere la condición de usuario (en adelante, el “Usuario”), quedando sujeto por tanto a cuanto queda establecido tanto en estas Condiciones de Uso de la Página Web y Aplicaciones móviles como en la Política de Privacidad y Protección de Datos. Ser usuario de este Sitio implica que Usted reconoce haber leído y aceptado las presentes condiciones. Si usted no está de acuerdo con las mismas le rogamos que no continúe usando la web de Jurista Digital.</p>
                    <p>El Usuario se compromete a utilizar esta Página o Aplicacion de conformidad con lo establecido en la legislación vigente así como en las presentes Condiciones de Uso, obligándose a abstenerse de utilizar la misma con fines o efectos ilícitos, contrarios a lo establecido en las presentes Condiciones, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la Página o impedir la normal utilización de la misma por parte de los demás Usuarios.</p>
                    <p>El acceso a la Página o Aplicaciones móviles no implica ningún tipo de garantía respecto a la idoneidad de los contenidos incluidos en la misma para fines particulares. En consecuencia, tanto el acceso a dicha Página Web o Aplicaciones móviles como el uso que pueda hacerse de la información y contenidos incluidos en la misma se efectúan bajo la exclusiva responsabilidad de quien lo realice.</p>
                    <p>Toda la información que facilite el Usuario deberá ser veraz. A estos efectos, el Usuario garantiza la autenticidad de todos aquellos datos que comunique como consecuencia de la cumplimentación de los formularios necesarios para la suscripción de los servicios ofrecidos por Click Aplicaciones SL, acceso a contenidos o áreas restringidas del Sitio y todo ello, de conformidad con lo establecido en la Política de Privacidad y Protección de Datos.</p>
                    <p>Los daños y perjuicios ocasionados por el Usuario como consecuencia del incumplimiento de cualquiera de las obligaciones a las que queda sometido por las presentes Condiciones de Uso, serán responsabilidad exclusiva del Usuario, respondiendo frente a Click Aplicaciones SL y/o cualquier tercero que pudiere haberse visto perjudicado por el incumplimiento del Usuario.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Hiperenlaces</h3>
                    <p>Los usuarios, y todas aquellas personas que tengan intención de establecer un hiperenlace entre su página Web, Aplicaciones o portal y el “Sitio Web” y Aplicaciones móviles” de Click Aplicaciones SL deberán cumplir las siguientes condiciones:</p>
                    <ul>
                        <li><p>El hiperenlace únicamente permitirá el acceso al “home – page” o página de inicio del “Sitio Web y Aplicaciones móviles”, pero no podrá reproducirlas de ninguna forma.</p></li>
                        <li><p>No se creará un “frame” sobre las páginas Web del “Sitio Web y Aplicaciones móviles”.</p></li>
                        <li><p>No se realizarán manifestaciones o indicaciones falsas, inexactas o incorrectas sobre las páginas Web del “Sitio Web y Aplicaciones móviles” y los servicios suministrados.</p></li>
                        <li><p>No se dará a entender por ningún modo que Juan José Castro Leitón ha autorizado el hiperenlace, o que ha asumido de alguna manera los servicios ofrecidos o puestos a disposición desde la página Web en la que se establece el hiperenlace.</p></li>
                        <li><p>A excepción hecha de aquellos signos que formen parte del mismo hiperenlace, la página en la que se establezca el hiperenlace no contendrá ningún signo distintivo, de los reconocidos por la Legislación vigente, de los que sea titular Click Aplicaciones S.L., salvo que haya sido expresamente autorizado por ésta.</p></li>
                        <li><p>La página Web o Aplicaciones móviles en la que se establezca el hiperenlace no contendrá contenidos contrarios a cualesquiera derechos de terceros, ni contendrá informaciones o contenidos ilícitos, contrarios a la moral o a las buenas costumbres generalmente aceptadas y al orden público.</p></li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Propiedad industrial  e intelectual</h3>
                    <p>La totalidad de este “Sitio Web y Aplicaciones móviles”: texto, imágenes, marcas comerciales, logotipos, archivos de audio y video, botones, archivos de software, combinaciones de colores, así como la estructura, selección, ordenación y presentación de sus contenidos son propiedad de Click Aplicaciones S.L., y se encuentran protegidos por las leyes españolas e internacionales sobre propiedad Industrial e Intelectual. Asimismo, y sin perjuicio de lo anteriormente citado, el contenido del “Sitio Web y Aplicaciones móviles” tiene la consideración de programa de ordenador y le es de aplicación toda la normativa española y comunitaria europea vigente en la materia.</p>
                    <p>Por el hecho del acceso que el usuario realiza al “Sitio Web y Aplicaciones móviles” no pueden entenderse cedidos al mismo ninguno de los derechos de explotación que existan, o puedan existir sobre dichos usos más allá del estrictamente necesario para el correcto uso del “Sitio Web y Aplicaciones móviles”.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Comunicaciones y notificaciones</h3>
                    <p>Todas las notificaciones realizadas por un usuario o tercero a Click Aplicaciones S.L., se considerarán eficaces siempre que se dirijan de alguna de las siguientes formas:</p>
                    <ul>
                        <li><p>Envío por correo postal a Click Aplicaciones S.L., con domicilio social en Avenida Olímpica 34 local 4, Madrid, Móstoles, 28935.</p></li>
                        <li><p>Envío por correo electrónico a nfo@integgra.es</p></li>
                    </ul>
                    <p>Si la comunicación se refiere a la realización de actividades ilícitas o a la infracción de derechos de propiedad industrial o intelectual debe adjuntar documentación que acredite los hechos, así como declaración expresa de la veracidad de la misma.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Ley aplicable y jurisdicción</h3>
                    <p>Click Aplicaciones S.L., excluye, con toda la extensión que permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a:</p>
                    <ul>
                        <li><p>Presencia de virus, o la presencia de otros elementos en los contenidos que puedan producir alteraciones en el sistema informático, documentos electrónicos o ficheros de los usuarios.</p></li>
                        <li><p>La transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos.</p></li>
                        <li><p>Falta de disponibilidad o de continuidad del funcionamiento del “Sitio Web y Aplicaciones móviles” y de los servicios, a la defraudación de la utilidad que los usuarios hubieren podido atribuir al “Sitio Web y Aplicaciones móviles” y a los servicios, a la falibilidad del “Sitio Web y Aplicaciones móviles” y de los servicios, y en particular, aunque no de modo exclusivo, a los fallos en el acceso a las distintas páginas Web o aplicaciones del sitio o aquellas desde las que se prestan los servicios.</p></li>
                        <li><p>El correcto funcionamiento de los dispositivos técnicos de enlace (tales como, entre otros, banners y botones), directorios y herramientas de búsqueda que permitan a los usuarios acceder a “Sitios Web y Aplicaciones móviles” pertenecientes y / o gestionados por terceros. La instalación de estos dispositivos técnicos tiene por único objeto facilitar a los usuarios, la búsqueda y acceso a la información, contenidos y servicios disponibles en Internet.</p></li>
                        <li><p>La calidad, licitud, fiabilidad y utilidad de la información, contenidos y servicios existentes en los sitios enlazados.</p></li>
                        <li><p>La utilización de los servicios y de los contenidos por parte de los usuarios, o que puedan deberse a la falta de veracidad, vigencia, exhaustividad y/o autenticidad de la información que los usuarios proporcionan a otros usuarios.</p></li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Uso profesional de la plataforma</h3>
                    <p>Jurista Digital está desarrollada para que los usuarios puedan llevar una relación activa tanto con la plataforma como con otros usuarios. Jurista Digital no se hace cargo de las opiniones, comentarios u otros malos usos que puedan hacer los usuarios dentro de la aplicación, aunque sí se compromete a moderar y censurar dichos comportamientos no deseables con la mayor prontitud posible. Huelga decir que los usuarios son plenamente responsables de las opiniones que emitan tanto en su muro de publicaciones, como en las notificaciones y en los propios artículos que escriban para la plataforma.</p>
                    <p>Jurista Digital se reserva el derecho de expulsar a un usuario de la plataforma de forma definitiva cuando haya mostrado un comportamiento inadecuado de forma reiterada, o no atienda a las amonestaciones recibidas.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Uso del blog</h3>
                    <p>Jurista Digital cuenta con un blog del que pueden participar libremente todos los usuarios que se encuentren registrados en la plataforma. Jurista Digital se reserva el derecho de matizar el contenido de los artículos, incluso en algunos casos vetar su publicación. Cuando un usuario escribe un artículo para Jurista Digital, acuerda ceder el uso y explotación del mismo a la plataforma incluso aunque el usuario cause baja en la misma. Todo ello,sin perjuicio a que en un momento concreto y bajo razones justificadas pueda ser modificado o removido a petición del propio usuario.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Relacion con otros miembros</h3>
                    <p>El usuario se compromete a mantener un tono y una conducta respetuosa con el resto de los miembros de la plataforma. Jurista Digital es una aplicación de naturaleza profesional y no está permitido hacer un uso distinto de la misma. Los insultos, amenazas o descalificaciones que se realicen contra otro usuario serán censurados, y en caso preciso, serán puestos en conocimineto de las autoridades judiciales.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Subscripción a servicios y adquisición de créditos</h3>
                    <p>Jurista digital ofrece a los usuarios juristas la posibilidad de acceder mediante una subscripción anual de pago a una serie de herramientas y aplicaciones. La subscripción anual no se renueva automaticamente. Podrá renovarla el usuario dentro de los 3 meses anteriores a la expiración de la subscripción activa, y una vez concretado el pago, se le sumarán 12 meses de subscripción al tiempo que le restase en la subscripción anterior. Jurista Digital se reserva el derecho a interrumpir el servicio de herramientas por labores de mantenimiento el tiempo indispensable para llevar a cabo dichas tareas, y causando el menor perjuicio posible a los usuarios.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Precios</h3>
                    <p>Los productos y servicios que oferta Jurista Digital tienen los siguientes costes:</p>
                    <p>Subscripción anular: 100 euros</p>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    <h3>Legislación aplicable</h3>
                    <p>El presente Aviso Legal y todas las relaciones que se establezcan entre Click Aplicaciones S.L. y los usuarios se regirán por lo establecido en la legislación española.</p>
                </Col>
            </Row>

        </Container>
      </BasicLayout>
    </>
  );
}