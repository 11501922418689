
import React,{useState} from 'react';
import "./contacform.scss";
import { Container } from "react-bootstrap";
import axios from 'axios'; 
import { Formik } from 'formik';
import Alert from '@mui/material/Alert';
import { SpinnerRoundOutlined } from 'spinners-react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';



export default function Contactform(props) {

  const [isLoading, setLoading] = useState();
  const [isAlert, setAlerting] = useState('');
  const label = { inputProps: { 'aria-label': 'Aceptas la política de privacidad de la empresa' } };




  return(
    <>
      {isLoading ? <Container style={{height:'50vh'}}><div className='row justify-content-center'><SpinnerRoundOutlined size={78} color="rgba(0, 212, 72, 1)" style={{marginTop:'150px'}}/><p className='text-center'>Enviando formulario</p></div></Container> :
      <Formik
      initialValues={{ name: '',email:'',telefono: '',content:'',priv:false }}
      validate={values => {
        const errors = {};
        //nombre
        
        if (!values.name) {
          errors.name = 'El nombre es requerido';
        } else if (
          !/^([A-Za-z ]){3,60}$/i.test(values.name)
        ) {
          errors.name = 'El formato del nombre no es válido';
        }
        //email
        if (!values.email) {
          errors.email = 'El email es requerido';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Dirección email no válida';
        }

        //telefono
        if (!values.phone) {
          errors.phone = 'El teléfono es requerido';
        } else if (
          !/^([0-9]){6,13}$/i.test(values.phone)
        ) {
          errors.phone = 'El número de teléfono no es válido';
        }
        //nombre

        if (!values.content) {
          errors.content = 'El mensaje es requerido';
        } else if (
          !/^([A-Za-z0-9 ]){10,500}$/i.test(values.content)
        ) {
          errors.content = 'El formato del mensaje no es válido';
        }
        if(values.priv==false) {
          errors.priv = 'Debe aceptar la política de privacidad';
      }
        console.log(errors)
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
/*         setTimeout(() => {
          console.log(values)
          setSubmitting(false);
        }, 400); */
        setLoading(true);

        
        const formData = new FormData();
        formData.append('values',JSON.stringify(values))
        const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
        }
        return  axios.post("https://cloud-111.clickaplicaciones.es/clickaplicaciones/consulta-web", formData,config).then(response=>{
          console.log(response);
          if(response.data.code==0){
                      
            setLoading(false);
            setAlerting('success');
            setTimeout(()=>{
                setAlerting('');
            },2000);
            console.log(isAlert)
        }else{
            setLoading(false); 
            setAlerting('error');
            setTimeout(()=>{
                setAlerting('');
            },2000);
            console.log(isAlert)
        }
        }).catch((error)=>{
          setLoading(false);
          setAlerting('error');
          setTimeout(()=>{
              setAlerting('');
          },2000);
          console.log(isAlert)
        });
      
          


      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <FormControl fullWidth>
                    <TextField id="name" label="Nombre" variant="outlined" name="name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} />
                    <span style={{color:'red'}}>{errors.name && touched.name && errors.name}</span>
                </FormControl>
              </div>
              <div className='mb-3'>
                <FormControl fullWidth>
                    <TextField id="email" label="Email" variant="outlined" name="email" type="text" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                    <span style={{color:'red'}}>{errors.email && touched.email && errors.email}</span>
                </FormControl>
              </div>
              <div className='mb-3'>
                <FormControl fullWidth>
                    <TextField id="price_sale" label="Teléfono" variant="outlined" name="phone" type="text" onChange={handleChange} onBlur={handleBlur} value={values.phone} />
                    <span style={{color:'red'}}>{errors.phone && touched.phone && errors.phone}</span>
                </FormControl>
              </div>
              <div className='mb-3'>
                <FormControl fullWidth>
                    <TextField multiline rows={4} id="content" label="Mensaje" variant="outlined" name="content" type="text" onChange={handleChange} onBlur={handleBlur} value={values.content} />
                    <span style={{color:'red'}}>{errors.content && touched.content && errors.content}</span>
                </FormControl>
              </div>
              <div className='mb-3'>
                <Checkbox  onChange={handleChange} onBlur={handleBlur} {...label} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} name="priv" value={values.priv} /> Aceptas la política de privacidad de la empresa             
                <span style={{color:'red',display:'block'}}>{errors.priv && touched.priv && errors.priv}</span>
              </div>

          <button className='botonPrimario' type="submit" disabled={isSubmitting}>Enviar</button>
        </form>
      )}
    </Formik>
    }
    
        {
                
                (isAlert=='success') ? 
                    <Alert style={{position:'absolute',top:'100px',right:'100px'}} severity="success">
                        Tu consulta ha sido enviada. En breve nos pondremos en contacto contigo.
                    </Alert>
                                  : <></>
                }
                {
                (isAlert=='error') ?
                
                    <Alert style={{position:'absolute',top:'100px',right:'100px'}} severity="error">
                        Algo salió mal. Inténtelo de nuevo o contacte con el administrador del sitio.
                    </Alert>
                  
                                  : <></>
        }
    </>
    


  );
}

