import './ventajasClick.scss';
import React from 'react';
import { Container,Row,Col,Image } from "react-bootstrap";


export default function VentajasClick() {
  return (
    <Container fluid>
    <Row className='my-5'>
        <Col>
            <Container>
                <Row>
                    <Col><h2 className='text-center my-4' style={{fontFamily:'PT Serif'}}>Porqué elegir Click</h2></Col>
                </Row>
                <Row className='my-4'>
                    <Col className='d-none d-md-block' xs={12} md={6}>
                        <div className='text-center mt-4'>
                            <Image src='./images/personas.png' fluid/>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <Container fluid>
                           
                            <Row className='mt-4'>
                               
                                <p className='px-1 px-md-3 px-md-5' style={{marginBottom:'40px'}}>Click ofrece servicios de soporte, pruebas y desarrollo de software personalizado de alta calidad. Los sistemas que desarrollamos ofrecen múltiples beneficios y ventajas a nuestros clientes en los sectores de alimentación, telecomunicaciones, automoción y asistencia sociosanitaria. Contamos con habilidades avanzadas y amplios recursos para crear soluciones a gran escala.</p>
                               <p className='px-1 px-md-3 px-md-5' style={{marginBottom:'40px'}}>Al igual que tú, elegimos tecnologías escalables y flexibles. La capacidad de nuestros ingenieros ya ha resuelto los desafíos de más de 60 clientes. Por si fuera poco, el equipo de desarrollo trabaja incansablemente en las más actuales tecnologías, potenciando la manipulación de datos con la ayuda de la IA, y creando un código robusto y reutilizable.</p>
                               <p className='px-1 px-md-3 px-md-5' style={{marginBottom:'40px'}}>¿No estás seguro de cómo impulsar el desarrollo de tu idea? Habla con Click. Tu futuro equipo te guiará hacia las soluciones y estrategias de software más óptimas.</p>
                               
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Col>
    </Row>
    
</Container>
  )
}
