import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./scss/casos.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import {Helmet} from "react-helmet";


export default function Gestionempresas() {
  return (
    
    <BasicLayout>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Gestión de empresas</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/gestion-empresas" />
                <meta name="description" content="Gestiona tu empresa de la forma más eficiente con el mejor software de gestión que encontrarás."></meta>
      </Helmet>
      <Container fluid>

        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >Gestión empresarial</h1>
          </Col>
        </Row>
        
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='./images/gestion-empresas.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>La gestión empresarial más fácil que nunca.</h3>
            <p>En Click, ofrecemos soluciones de gestión empresarial diseñadas para ayudar a las organizaciones a optimizar sus operaciones, mejorar la toma de decisiones y alcanzar sus objetivos de manera más eficiente. Entendemos que cada empresa es única, por lo que trabajamos con nuestros clientes para desarrollar soluciones personalizadas que se adapten a sus necesidades específicas y dinámicas de negocio.</p>
            <p>Nuestras herramientas de gestión empresarial abarcan desde la planificación de recursos empresariales (ERP) hasta la gestión de relaciones con clientes (CRM), finanzas, inventarios y proyectos. Estas soluciones permiten a las empresas automatizar procesos clave, reducir costos operativos y mejorar la productividad general, todo mientras se centraliza la información en una única plataforma accesible y segura.</p>
            <p></p>
          </Col>
        </Row>

        <Row>
          <Col className='my-2 text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 style={{marginTop:'40px',marginBottom:'40px'}}>"La gestión de la empresa ahora está al alcance de cualquiera. ¡Aprovecha esta oportunidad!"</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>Trato personalizado</h3>
            <p>Con nuestras soluciones de gestión empresarial, los líderes empresariales pueden obtener una visión integral de sus operaciones en tiempo real, lo que facilita la toma de decisiones estratégicas basadas en datos. Al integrar todas las áreas de tu negocio en una única solución, ayudamos a eliminar silos de información, aumentar la eficiencia y garantizar que todos los equipos trabajen en sincronía hacia los mismos objetivos.</p>
            <p>Además, nuestras soluciones están diseñadas para escalar junto con el crecimiento de tu empresa, asegurando que a medida que tus necesidades evolucionen, nuestras herramientas también lo hagan. En Click, nos comprometemos a ofrecer un soporte continuo, asegurando que nuestros sistemas de gestión empresarial se mantengan actualizados y en pleno funcionamiento.</p>
            <p>Confía en Click para llevar la gestión de tu empresa al siguiente nivel, impulsando la eficiencia, el control y el crecimiento sostenible.

</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image className='imgsize' src='./images/gestion-empresas2.webp' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             