import React from 'react';
import "./centrix.scss";
import { Container,Row } from "react-bootstrap";


export default function Centrix() {
  return (
    <>
        <Container fluid className="fondo">
            <Row>
                <div className='offset-md-6 col-12 col-md-6'>
                    <div className='cajaTexto'>
                        <h4>CENTRIX</h4>
                        <p><i>Hasta donde tu imaginación te pueda llevar.</i></p>
                        <p>La aplicación lider en gestión de accesos a emplazamientos tecnológicos. Actualmente propiedad de Orange España. Desarrollada y mantenida por Click.</p>
                        <p>Versatilidad garantizada con sus tres versiones: escritorio, web y móvil.</p>
                    </div>
                </div>
            </Row>
        </Container>
    </>
  )
}