import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';

export default function PoliticaPrivacidad() {
  return (
    <>
      <BasicLayout >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Política de privacidad</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/politica-privacidad" />
                <meta name="description" content=""></meta>
                <meta name="robots" content="noindex,nofollow"></meta>
            </Helmet>
          <Container>
            <Row className='mt-5'>
                <Col>
                    <p>Usted, como usuario de la web, debe conocer que para un efectivo cumplimiento de los servicios que más adelante se van a detallar, no se le solicitará información personal a menos que realmente sea necesaria para prestarle los servicios aquí ofrecidos. Además, garantizamos que nunca vamos a compartir su información personal con nadie, excepto para cumplir con la ley o en caso de que contemos con su autorización expresa. Tampoco utilizaremos sus datos personales con una finalidad diferente a la expresada en esta política de privacidad.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>PROCESAMIENTO DE DATOS PERSONALES, FINALIDAD DEL TRATAMIENTO Y CONSENTIMIENTO PARA LA CESIÓN DE DATOS</h3>
                    <p>Desde la web se garantiza el grado de protección adecuado, adoptándose las medidas técnicas y organizativas necesarias para garantizar la seguridad y que legalmente resulten necesarias con el objeto de evitar su pérdida, deterioro, extravío o el acceso a terceros no autorizados de los datos de carácter personal que se recaban aquí. Controlamos periódicamente nuestros sistemas para detectar posibles vulnerabilidades y ataques. No obstante, no podemos garantizar la seguridad de la información que nos envíes. No hay ninguna garantía de que no se pueda acceder, divulgar, alterar o destruir los datos si se produce una filtración en alguna de nuestras medidas de seguridad físicas, técnicas o de gestión.</p>
                    <p>Usted, como usuario de la web, debe conocer que para un efectivo cumplimiento de los servicios que más adelante se van a detallar, no se le solicitará información personal a menos que realmente sea necesaria para prestarle los servicios aquí ofrecidos. Además, garantizamos que nunca vamos a compartir su información personal con nadie, excepto para cumplir con la ley o en caso de que contemos con su autorización expresa. Tampoco utilizaremos sus datos personales con una finalidad diferente a la expresada en esta política de privacidad.</p>
                    <p>La base legal para el tratamiento de sus datos está fundada en la obtención de su consentimiento, de acuerdo con el artículo 6.1.a del Reglamento (UE) 679/2016. No obstante, el usuario tiene que conocer la existencia del derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada. La web no realiza cesiones de datos a terceras personas, ni transferencias internacionales que no sean aquellas permitidas por la Agencia Española de Protección de Datos o siempre y cuando estén consentidas expresamente por el usuario. Por lo tanto, todas aquellas cesiones de datos que se efectúen se harán bajo el marco de protección indicado por la Agencia Española de Protección de Datos, o siempre y cuando estén consentidas expresamente por el usuario y a los países cuya seguridad y confidencialidad esté garantizada.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>RECOPILACIÓN DE LOS DATOS Y DURACIÓN DE LA CONSERVACIÓN</h3>
                    <p>La captura de los datos en la web se realiza a través de:</p>
                    <p>Formulario de contacto</p>
                    <p>Los datos recibidos por el usuario serán conservados en esta web o aplicación móvil durante un periodo de tiempo que puede ser distinto, dependiendo del usuario. Por defecto, los datos personales serán guardados:</p>
                    <ul>
                        <li><p>Mientras se resuelva la consulta realizada.</p></li>
                        <li><p>Mientras no ejercite su derecho de supresión.</p></li>
                    </ul>
                    <p>No obstante, existe la posibilidad de que conservemos sus datos personales incluso después de haber cerrado la cuenta si es razonablemente necesario para cumplir con nuestras obligaciones legales (incluidas las peticiones de las fuerzas del orden, Agencia Tributaria o SEPBLAC), reunir los requisitos reglamentarios, resolver disputas, mantener la seguridad, evitar casos de fraude y abuso, aplicar nuestras Condiciones de uso o cumplir su solicitud de darse de baja de futuros mensajes que reciba de CLICK APLICACIONES</p>
                    <p>El Usuario por la presente garantiza que los Datos Personales proporcionados son ciertos y exactos y se compromete a notificar cualquier cambio o modificación de estos. En el caso de que se aporten Datos Personales de terceros, el Usuario se responsabiliza de haber informado y haber obtenido el consentimiento de estos para ser aportados con las finalidades indicadas en los apartados correspondientes de la presente Política de Privacidad y Cookies.</p>
                    <p>Cualquier pérdida o daño causado a la página web o al responsable del portal web o a cualquier tercero mediante la comunicación de información errónea, inexacta o incompleta en los formularios de registro será responsabilidad exclusiva del Usuario.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>FINALIDADES DEL TRATAMIENTO</h3>
                    <p>Dónde recabamos los datos que se recopilan Finalidad y base jurídica:</p>
                    <p>Formulario de contacto Obligatorios: nombre, email, teléfono y mensaje, CLICK APLICACIONES recabará estos datos para contestar a la consulta formulada. La base jurídica para el tratamiento de datos se basa en el consentimiento expreso recogido en el art. 6.1.a RGPD</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>CATEGORÍAS DE LOS DESTINATARIOS DE LOS DATOS PERSONALES</h3>
                    <p>Todas las utilidades ofrecidas por terceros son estrictamente necesarias para el desarrollo de los servicios de esta web y han sido seleccionadas atendiendo al cumplimiento de la legalidad vigente.</p>
                    <p>Trabajamos estrechamente con terceros que puedan ser destinatarios de sus datos personales, por ejemplo:</p>
                    <p>Nuestros socios comerciales que son plataformas de redes sociales y que pueden proporcionarte servicios relacionados, tales como la conexión de la información de tu perfil, desde sus plataformas de redes sociales a nuestra plataforma.</p>
                    <p>Nuestro servidor de hosting, o de plataforma web.</p>
                    <p>Nuestros socios comerciales que pueden anunciar sus servicios en nuestra web, los cuales puede decidir suscribirse.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>EJERCICIO DE LOS DERECHOS DEL INTERESADO</h3>
                    <p>Una vez que, como usuario de la WEB o aplicación móvil nos ha facilitado sus datos personales, la legislación vigente le permite como interesado de sus datos:</p>
                    <ul>
                        <li><p>Solicitar el acceso a los datos personales relativos al interesado.</p></li>
                        <li><p>Nuestro servidor de hosting, o de plataforma web.</p></li>
                        <li><p>Solicitar su rectificación o supresión.</p></li>
                        <li><p>Solicitar la limitación de su tratamiento.</p></li>
                        <li><p>Oponerse al tratamiento.</p></li>
                        <li><p>Solicitar la portabilidad de los datos.</p></li>
                
                    </ul>
                    <p>Para ejercer cualquiera de los derechos descritos, el interesado tiene que hacer llegar dicha solicitud acompañada de fotocopia del DNI, pasaporte u otro documento válido que identifique al Usuario. Para ello, deberá enviar dicha solicitud a la siguiente dirección de correo electrónico: info@juristadigital.com. Asimismo, la solicitud deberá contener: nombre y apellidos del interesado, fotocopia de su documento nacional de identidad, o de su pasaporte u otro documento válido que lo identifique y, en su caso, de la persona que lo represente, petición en que se concreta la solicitud, dirección a efectos de notificaciones, fecha y firma del solicitante. El ejercicio de los derechos será gratuito para el interesado excepto cuando las solicitudes sean manifiestamente infundadas o excesivas, especialmente debido a su carácter repetitivo. En este caso, el responsable del tratamiento podrá cobrar un canon razonable en función de los costes administrativos o negarse a actuar respecto de la solicitud.</p>
              </Col>
            </Row>
            <Row>
                <Col>
                    <h3>RECLAMACIONES Y EJERCICIO DE DERECHOS</h3>
                    <p>Los interesados pueden dirigir sus requerimientos a las Autoridades de protección de datos, si creen que hay un problema con la forma en que estamos manejando sus datos de carácter personal, mediante la llamada tutela de derechos.</p>
                    <p>Dicho canal de comunicación podrá efectuarse a través del siguiente enlace de la Agencia Española de Protección de Datos (AEPD):</p>
                    <p>https://sedeagpd.gob.es/sede-electronica-web/vistas/formReclamacionDerechos/reclamacionDerechos.jsf</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>COMUNICACIONES Y PROMOCIONES COMERCIALES</h3>
                    <p>De acuerdo con la Ley de servicios de la sociedad de la información y de comercio electrónico, la web no realiza prácticas de SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o autorizados por el usuario. En consecuencia, en cada uno de los formularios habidos en la web, el usuario tiene la posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la información comercial puntualmente solicitada.</p>
                    <p>Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio electrónico, la web se compromete a no enviar comunicaciones de carácter comercial sin identificarlas debidamente.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>MENORES DE EDAD</h3>
                    <p>Con carácter general, la web no tratará información personal, con conocimientos fehacientes, de menores de catorce (14) años.</p>
                    <p>Para el caso de que la web llevando a cabo actividad alguna de control, descubra la recopilación involuntaria de información respecto a menores de 14 años, llevará a cabo todas aquellas medidas necesarias que, como prestador de servicio y responsable de tratamiento, tiene obligación de emprender y poder así suprimir tal información, en la mayor brevedad posible, salvo en aquellos supuestos que, por razón de legislación aplicable, sea necesario conservar.</p>
                    <p>De conformidad con lo establecido en el Reglamento Europeo de Protección de Datos, los mayores de 13 años están legitimados para otorgar su consentimiento, excepto en aquellos casos en los que la ley exija para su prestación la asistencia de los titulares de la patria potestad o tutela.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>ACEPTACIÓN Y CONSENTIMIENTO</h3>
                    <p>El usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de estos por parte de CLICK APLICACIONES en la forma y para las finalidades indicadas en esta política de privacidad.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>CAMBIOS EN LA POLÍTICA DE PRIVACIDAD</h3>
                    <p>La web se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, el Prestador anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.</p>
                </Col>
            </Row>
        </Container>
      </BasicLayout>
    </>
  );
}