import React from 'react'
import BasicLayout from './layouts/basicLayout/BasicLayout'
import Jumbotron from '../src/components/viewmodules/Jumbotron/Jumbotron';
import Cajas from '../src/components/viewmodules/Cajas/cajas';
import Desarrollo from '../src/components/viewmodules/Desarrollo/desarrollo';
import Tech from '../src/components/viewmodules/Tech/tech';
import Centrix from '../src/components/viewmodules/Centrix/centrix';
import VentajasClick from './components/viewmodules/VentajasClick/ventajasClick';
import ContactLan from './components/viewmodules/ContactLan/contactLan';
import { Helmet } from 'react-helmet';


export const App = () => {
  return (
        <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Desarrollo de aplicaciones | Ciberseguridad</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com" />
                <meta name="description" content="Desarrolla la aplicación que tienes en mente y haz realidad tus sueños. Asesoramiento en seguridad informática gracias a las últimas tecnologías."></meta>
              </Helmet>
            <BasicLayout>
                <Jumbotron/>
                <Cajas/>
                <Desarrollo/>
                <Tech/>
                <VentajasClick/>
                <Centrix/>
                <ContactLan/>
            </BasicLayout>
        </>

        
  )
}
