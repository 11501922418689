import React, {useState} from 'react'
import { Container,Row,Col, Image } from"react-bootstrap";
import { BsClock } from 'react-icons/bs';
import './scss/Blog.scss';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import POST_CATEGORIES from '../resources/post_categories.json';
import POST_META from '../resources/post_meta.json';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function Blog() {
  const categories = POST_CATEGORIES;
  const post = POST_META;
  const [catSel,setId] = useState(0);
  

  return (
    <>
        <BasicLayout>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Blog</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/blog" />
                <meta name="description" content="En el blog de Click podrás mantenerte informado de todas nuestras novedades. Si te gusta la tecnología, ¡este es tu blog! ."></meta>
            </Helmet>
          <Container fluid style={{backgroundColor:'rgb(233, 233, 233)'}}>
            <Row>
                <Col xs={12} md={9}>
                  <Container style={{ marginTop: '80px' }}>
                    <Row>
                    {
                      catSel === 0 ?
                        post.map((item)=>{
                          return (
                            <Col xs={12} md={6} lg={4} style={{marginBottom:'60px'}}>
                              <div key={item.id} className="card p-1 p-lg-4" >
                                <Image src={item.img} alt={item.alt_img} style={{width:'100%'}} fluid />
                                <Row>
                                  <Col xs={12}><BsClock size='.9em' style={{marginRight:'5px'}}/><span style={{fontSize:'.8em'}}>{item.fch_publish}</span></Col>
                                </Row>
                                <div className='mt-2'>
                                  <p>{item.title}</p>
                                  <Link to={item.route}><button className='botonPrimario mb-4' style={{marginInline:'calc(50% - 75px)',width:'150px'}}>Enviar</button></Link>
                                </div>
                              </div>
                            </Col>
                          )
                        })
                      :
                      post.map((item)=>{
                        if(catSel === item.category){
                          return (
                            <Col xs={12} md={6} lg={4} style={{marginBottom:'60px'}}>
                            <div key={item.id} className="card p-0 p-md-1 p-lg-4" >
                              <Image src={item.img} alt={item.alt_img} style={{width:'250px',height:'180px'}} fluid />
                              <Row>
                                <Col xs={12}><BsClock size='.9em' style={{marginRight:'5px'}}/><span style={{fontSize:'.8em'}}>{item.fch_publish}</span></Col>
                              </Row>
                              <div className='mt-2'>
                                <p>{item.title}</p>
                                <Link to={item.route}><button className='botonPrimario mb-4' style={{marginInline:'calc(50% - 75px)',width:'150px'}}>Enviar</button></Link>
                                </div>
                            </div>
                          </Col>
                          )
                        }

                      })

  
                    }
                    </Row>
{/*                     <Row>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item"><a className="page-link" href="#">Anterior</a></li>
                          <li className="page-item"><a className="page-link" href="#">1</a></li>
                          <li className="page-item"><a className="page-link" href="#">2</a></li>
                          <li className="page-item"><a className="page-link" href="#">3</a></li>
                          <li className="page-item"><a className="page-link" href="#">Siguiente</a></li>
                        </ul>
                      </nav>
                    </Row> */}
                  </Container>
                    

                </Col>
                <Col className='d-none d-lg-block' style={{marginTop:'0px',backgroundColor:'#131a24'}}  lg={3}>
                    <h4 className='my-5 text-center' style={{fontFamily:'Varino',color:'#00d448'}}>Categorias</h4>
                    <ul className='listCategory'>
                      {categories.map(cat=>{
                        return (
                          <li>
                            <div>
                              <p className='mb-0' onClick={() => setId(cat.id)}>{cat.name}</p>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                </Col>
            </Row>
          </Container>
           
        </BasicLayout>
    </>
    
  )
}
