import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';


export default function proyectoedad() {
  return (
    
    <BasicLayout>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Proyecto Edad y Vida</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/proyectoedad" />
                <meta name="description" content=""></meta>
            </Helmet>
      <Container fluid>

        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >Proyecto de edad y vida</h1>
          </Col>
        </Row>

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='./images/proyecto_edad_y_vida.jpg' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
          <p>A través de la Fundación Edad y Vida, y sus entidades colaboradoras se está logrando validar el test de idoneidad de Anubis. El test de idoneidad es un formulario que se ha diseñado para revolucionar las mediciones físicas y de salud. En solo unos pocos minutos, realiza un test 360 de una persona mayor y logra captar los aspectos principales, extrayendo una valoración global acerca del bienestar psicoemocional de la persona.</p>
          <p>Además de la Fundación Edad y Vida, colaboran en este proyecto las siguientes entidades: Colisee, Barna Salud, Eulen, etc.</p>
          </Col>
        </Row>
        
       

        <Row>
          <Col className='text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 className='my-4'>"El objetivo principal del test de idoneidad es mejorar la vida de los mayores."</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
          <p>Tras la realización del test, cuando se detectan carencias en el bienestar del mayor, se propone un programa de mejoría funcional, y si precisa de algún tipo de financiación, se le puede también ofrecer alguna solución.</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
            <Image className='imgsize' src='./images//proyecto_edad_y_vida2.jpg' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             