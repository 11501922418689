import './desarrollo.scss';
import React from 'react';
import { Container,Row,Col, Image } from "react-bootstrap";



export default function Desarrollo() {
  return (
    <Container fluid>
    <Row className='my-5'>
        <Col>
            <Container>
                <Row>
                    <Col><h2 className='text-center my-4' style={{fontFamily:'PT Serif'}}>Desarrollo de aplicaciones</h2></Col>
                </Row>
                <Row className='my-4'>
                    <Col xs={12} className='mt-4'>
                            <p className='px-1 px-md-3 px-md-5' style={{marginBottom:'40px'}}>Por <strong>desarrollo de aplicaciones</strong> se entiende al proceso de crear software diseñado para ejecutar tareas específicas en dispositivos, ya sea en computadoras, smartphones o tablets. Este proceso incluye varias etapas, desde la conceptualización y diseño hasta la programación, pruebas y finalmente la implementación y mantenimiento.</p>
                            <p className='px-1 px-md-3 px-md-5' style={{marginBottom:'40px'}}>El <strong>desarrollo de aplicaciones</strong> puede adaptarse a diferentes necesidades, ya sea para consumidores (B2C) o empresas (B2B), y puede abarcar sectores como entretenimiento, educación, salud, finanzas, etc.</p>
                            <p className='px-1 px-md-3 px-md-5' style={{marginBottom:'40px'}}>Elementos clave:</p>
                            <p className='px-1 px-md-3 px-md-5'><Image src='./images/check.png' style={{width:'15px',marginRight:'20px'}} fluid/>
Análisis de requisitos: Se identifican las necesidades del usuario o negocio para definir las funcionalidades que la aplicación debe cumplir.</p>
                            <p className='px-1 px-md-3 px-md-5'><Image src='./images/check.png' style={{width:'15px',marginRight:'20px'}} fluid/>Diseño de la arquitectura: Se estructura la aplicación, eligiendo tecnologías y frameworks que se usarán, y diseñando la interfaz de usuario (UI).</p>
                            <p className='px-1 px-md-3 px-md-5'><Image src='./images/check.png' style={{width:'15px',marginRight:'20px'}} fluid/>Codificación: Los desarrolladores escriben el código fuente de la aplicación utilizando lenguajes de programación como Java, Python, Swift, etc.</p>
                            <p className='px-1 px-md-3 px-md-5'><Image src='./images/check.png' style={{width:'15px',marginRight:'20px'}} fluid/>Pruebas: Se realizan pruebas funcionales y de rendimiento para detectar y corregir errores.</p>
                            <p className='px-1 px-md-3 px-md-5'><Image src='./images/check.png' style={{width:'15px',marginRight:'20px'}} fluid/>Implementación y mantenimiento: La aplicación se lanza al mercado o se implementa en los sistemas del cliente, con soporte continuo y mejoras posteriores.</p>
                    
                            <p className='px-1 px-md-3 px-md-5 mt-5'>Existen varios tipos de desarrollo de aplicaciones que trabajamos en Click, que se diferencian principalmente por la tecnología utilizada y la plataforma en la que operan. A continuación, te presento los tipos más comunes:</p>
                            <p className='px-1 px-md-3 px-md-5'><strong>1.  Desarrollo de aplicaciones web</strong></p>
                            <p className='px-1 px-md-3 px-md-5'>Las aplicaciones web se ejecutan directamente en un navegador (como Chrome o Safari) y no requieren instalación. Se desarrollan usando tecnologías web como HTML, CSS, y JavaScript. Son multiplataforma y no requieren instalación en los dispositivos. Sin embargo, tienen acceso limitado a las funcionalidades del dispositivo y dependen de la conexión a internet.</p>
                            <p className='px-1 px-md-3 px-md-5'><strong>2. Desarrollo de aplicaciones híbridas</strong></p>
                            <p className='px-1 px-md-3 px-md-5'>Combinan elementos de las aplicaciones nativas y web. Se desarrollan utilizando tecnologías web, pero se empaquetan como aplicaciones nativas para su distribución en tiendas de aplicaciones (utilizando frameworks como Ionic o React Native).Permiten desarrollar una sola aplicación para múltiples plataformas. Sin embargo, aunque ofrecen más funcionalidad que las aplicaciones web, el rendimiento no es tan óptimo como el de las aplicaciones nativas.</p>
                            <p className='px-1 px-md-3 px-md-5'><strong>3. Desarrollo de aplicaciones multiplataforma</strong></p>
                            <p className='px-1 px-md-3 px-md-5'>Utilizan frameworks como Flutter o Xamarin, que permiten escribir un solo código base que se puede ejecutar en diferentes plataformas (Android, iOS, y a veces en web o escritorio). Costes y tiempo de desarrollo reducido al usar solo una base de código.</p>
                            <p className='px-1 px-md-3 px-md-5'><strong>4. Desarrollo de aplicaciones para escritorio</strong></p>
                            <p className='px-1 px-md-3 px-md-5'>Aplicaciones desarrolladas específicamente para ejecutarse en sistemas operativos de escritorio como Windows, macOS o Linux, usando tecnologías como C#, Java, Electron (para aplicaciones web empaquetadas). Aprovechan al máximo las capacidades del hardware del ordenador. Por contra, no son adecuadas para dispositivos móviles.</p>
                    </Col>
                </Row>
            </Container>
        </Col>
    </Row>
    
</Container>
  )
}
