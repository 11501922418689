import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./scss/casos.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';


export default function Adaptanegocio() {
  return (
    
    <BasicLayout>

      <Container fluid>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Adapta tu negocio a la normativa</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/adapta-negocio-normativa" />
                <meta name="description" content="Adapta tu negocio y cumple con toda la normativa española y europea. Es el momento de completar la digitalización de tu negocio."></meta>
            </Helmet>
        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >Adapta tu negocio a la normativa</h1>
          </Col>
        </Row>
        
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='../../images/textolegal.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>Textos legales</h3>
            <p>Sabemos que el cumplimiento normativo es esencial, no solo para el éxito y la sostenibilidad del negocio, sino para transmitir confianza y evitar determinadas sanciones. La normativa legal, tanto estatal como europea, evoluciona constantemente, y adaptarse a ellas puede ser un desafío. Por eso, ofrecemos soluciones diseñadas para asegurar que tu empresa cumpla con todas las leyes y regulaciones vigentes, minimizando riesgos y evitando sanciones.</p>
            <p>Nuestra experiencia en cumplimiento normativo abarca diversas áreas, como protección de datos, regulaciones fiscales, clausulas comerciales, entre otras. Trabajaremos estrechamente con tu equipo para identificar las áreas de riesgo, implementar políticas y procesos adecuados, y garantizar que todas las operaciones de tu empresa se ajusten a los estándares legales aplicables. Esto no solo protege tu negocio de posibles multas o litigios, sino que también fortalece su reputación y aumenta la confianza de tus clientes y socios.</p>
          </Col>
        </Row>

        <Row>
          <Col className='my-2 text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 style={{marginTop:'40px',marginBottom:'40px'}}>"El correcto cumplimiento de la norma, además de una obligación legal, transmite confianza sobre tu negocio."</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>La clave es la concienciación.</h3>
            <p>Además, ofrecemos consultoría continua, auditorías de cumplimiento y formación para garantizar que todos los miembros de tu equipo estén alineados con las mejores prácticas legales.</p>
            <p>El cumplimiento no tiene por qué ser un obstáculo, sino una oportunidad para mejorar la gestión de tu empresa, optimizar procesos y asegurar un crecimiento sostenible. Con nuestro apoyo, tu empresa no solo cumplirá con la normativa actual, sino que estará preparada para enfrentar cualquier cambio regulatorio en el futuro.</p>
            <p>Confía en Click para guiarte en el proceso de adaptación legal y asegurar que tu negocio opere con total seguridad y confianza.</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image className='imgsize' src='./images/adapta-negocio.webp' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             