import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./scss/casos.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';


export default function Appweb() {
  return (
    
    <BasicLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Aplicaciones web</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/appweb" />
                <meta name="description" content="Crea las mejores aplicaciones web con nuestros desarrollos personalizados. Esta oportunidad está a tu alcance."></meta>
            </Helmet>
      <Container fluid>

        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >Aplicaciones web</h1>
          </Col>
        </Row>
        
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='./images/appweb.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>Ayudar a la empresa</h3>
            <p>Click, se especializa en el desarrollo de aplicaciones web que ayudan a las empresas a modernizarse y crecer en un entorno digital en constante cambio. Sabemos que, para mantener la competitividad, las empresas necesitan soluciones tecnológicas que se adapten a sus necesidades específicas, y nuestras aplicaciones web están diseñadas para hacer precisamente eso.</p>
            <p>Nuestras aplicaciones web son completamente personalizables y accesibles desde cualquier dispositivo, lo que facilita el trabajo remoto, la gestión en tiempo real y la mejora continua de los procesos operativos. Desde sistemas de gestión empresarial hasta plataformas de comercio electrónico, cada solución está pensada para optimizar el rendimiento y reducir costos, haciendo que las operaciones sean más eficientes y escalables.</p>
          </Col>
        </Row>

        <Row>
          <Col className='my-2 text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 style={{marginTop:'40px',marginBottom:'40px'}}>"La solución que necesitas bajo arquitectura cliente/servidor. Agilidad y eficiencia en una sola herramienta"</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>Aplicaciones web flexibles y customizables.</h3>
            <p>Gracias a nuestra forma de trabajar, nuestros clientes forman parte del proyecto desde el primer momento. Además de ser informados de cada cambio o avance que se producen, pueden participar del diseño y las funcionalidades aportando experiencias e ideas.</p>
            <p>Nuestro enfoque en la experiencia del usuario garantiza aplicaciones intuitivas que mejoran la interacción con tus clientes y empleados. Además, nuestro equipo ofrece un soporte continuo, asegurando que la aplicación siempre funcione de manera óptima y esté preparada para adaptarse a nuevas demandas del mercado.</p>
            <p>En Click, nos enorgullecemos de desarrollar aplicaciones web que marcan la diferencia, impulsando el crecimiento, la eficiencia y la transformación digital de nuestros clientes.</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image className='imgsize' src='./images/appweb2.webp' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             