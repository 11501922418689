import React from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import BasicLayout from '../../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';




export default function VivInvNudaProp() {

     
   
    var publicacion = {
       
      "id":3,
      "title":"Vivienda inversa vs Nuda propiedad: inconvenientes y ventajas.",
      "extract":"En Capital Radio han hablado sobre la Hipoteca inversa y la Nuda propiedad.",
      "fch_publish":"19 de diciembre de 2022",
      "author":"Click",
      "category":[2],
      "alt_img":"Vivienda inversa vs nuda propiedad",
      "img":"../../images-blog/radio.jpg",
      "route":"/blog/vivienda-inversa-vs-nuda-propiedad-inconvenientes-y-ventajas",
      "description":"La vivienda inversa y la nuda propiedad son dos modalidades de financiación con matices diferentes."
  };



    return (
      <BasicLayout>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Vivienda inversa vs nuda propiedad</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/blog/vivienda-inversa-vs-nuda-propiedad-inconvenientes-y-ventajas" />
                <meta name="description" content="Vivienda inversa y nuda propiedad son formas de financiación gracias a tu vivienda. Asesórate para saber cual te conviene."></meta>
            </Helmet>
          <Container fluid>
            <Row className='my-5'>
              <Col>
                <h1 style={{color:'#29363b'}} className='text-center'>{publicacion.title}</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={9}>
                <Container fluid>
                    <Row className='mb-3'>
                      <Col><span style={{fontSize:'.8em'}}>Fecha de publicación: <strong>{publicacion.fch_publish}</strong></span><span style={{fontSize:'.8em'}}>, Autor: <strong>{publicacion.author}</strong></span></Col>
                      <Col className='text-end'></Col>
                    </Row>
                    <Row>
                      <Col>
                        <Image src={publicacion.img} fluid />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <p>En Capital Radio han hablado sobre la Hipoteca inversa y la Nuda propiedad. Sobre cuales son las características, ventajas e inconvenientes de cada una de estas formas de financiación. Si quieres leer y escuchar la noticia completa, accede haciendo click aquí: <a href="https://www-capitalradio-es.cdn.ampproject.org/c/s/www.capitalradio.es/amp/noticias/otros/inmobiliario/vivienda-inversa-vs-nuda-propiedad-inconvenientes-ventajas_114279148.html">Vivienda inversa vs Nuda propiedad</a>.</p>
                      </Col>
                    </Row>
                   
                </Container>
              </Col>
            </Row>
          </Container>
      </BasicLayout>
    )
}