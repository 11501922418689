import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import Cvform from '../components/cvform/cvform';
import { Helmet } from 'react-helmet';

export default function Trabaja() {
  return (
      <BasicLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Trtabaja con nosotros</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/trabaja-con-nosotros" />
                <meta name="description" content="¿Te gustaría ser parte del equipo de Click? Déjanos tu CV y podrás forma parte de los próximos procesos de selección."></meta>
            </Helmet>
          <Container>
            <Row>
              <Col xs={12}>
                <h1 className='text-dark text-center my-5'>¿Te gustaría formar parte de nuestro equipo?</h1>
                <p className='text-center'>Envíanos tus datos y cv para formar parte de los próximos procesos de selección.</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='my-4'>
                <Cvform/>
              </Col>
            </Row>
          </Container>
        </BasicLayout>
  )
}
