import React from 'react';
import "./Menu.scss";
import { Container,Image,Nav,Navbar,NavDropdown } from "react-bootstrap";
import { Switch, Route, Link } from 'react-router-dom';








export default function Menu() {




   return (
     <header className="menu">
 
    <Navbar collapseOnSelect expand="lg" className="navbarr">
      <Container>
        <Navbar.Brand as={Link} to="/"><Image src='../../images/logo_click_negro.png' alt="Click" style={{width:'100px'}} fluid/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav style={{ float:'right', marginLeft:'auto' }}>
           
            <NavDropdown style={{ marginRight:'30px',color:'#456288'}} title="Nuestra empresa" id="basic-nav-dropdown">
            <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/about">El equipo</NavDropdown.Item>
              <NavDropdown.Item style={{ color:'#456288' }} href="/mision">Misión, Visión y valores</NavDropdown.Item>
              <NavDropdown.Item style={{ color:'#456288' }} href="/trabaja-con-nosotros">Trabaja con nosotros</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown style={{ marginRight:'30px',color:'#456288'}} title="Desarollo de software" id="basic-nav-dropdown">
              <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/appweb">Aplicaciones web</NavDropdown.Item>
              <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/gestion-empresas">Gestion empresarial</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown style={{ marginRight:'30px',color:'#456288'}} title="Ciberseguridad" id="basic-nav-dropdown">
              <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/adapta-negocio-normativa">Adapta tu negocio a la normativa</NavDropdown.Item>
              <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/auditorias-web">Auditoría web</NavDropdown.Item>
              <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/auditorias-proteccion-datos">Auditorias proteccion de datos</NavDropdown.Item>
              {/* <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/formacion">Formación</NavDropdown.Item> */}
              {/* <NavDropdown.Item style={{ marginRight:'30px',color:'#456288'}} href="/utility">Utilidades</NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown style={{ marginRight:'30px',color:'#456288' }} title="Casos de éxito" id="basic-nav-dropdown">
              <NavDropdown.Item style={{ color:'#456288' }} href="/centrix">Centrix</NavDropdown.Item>
              <NavDropdown.Item style={{ color:'#456288' }} href="/talex">Talex</NavDropdown.Item>
              <NavDropdown.Item style={{ color:'#456288' }} href="/erpclick">ERP Click</NavDropdown.Item>
              <NavDropdown.Item style={{ color:'#456288' }} href="/proyectoedad">Proyecto Edad y Vida</NavDropdown.Item>
            </NavDropdown>
           
            <Nav.Link href="/blog" style={{ marginRight:'30px',color:'#456288' }}>Blog</Nav.Link>
            <Nav.Link href="/contact" style={{ color:'#456288' }}>Contacto</Nav.Link>
            {/* <Nav.Link style={{marginLeft:'20px'}} href="#"><img src="../../images/profile.png" width="30" height="30"/></Nav.Link> */}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
 
    </header>
  ) 

}






