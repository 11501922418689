import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./scss/casos.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';



export default function Auditoriasweb() {
  return (
    
    <BasicLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Auditorias web</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/auditorias-web" />
                <meta name="description" content="Con auditorias web podrás conocer las vulnerabilidades de tu sitio web y estarás más preparado para protegerte de ciberataques."></meta>
            </Helmet>
      <Container fluid>

        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >Auditoría Web</h1>
          </Col>
        </Row>
        
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='./images/auditorias-web.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>La auditoría web como herramienta para conocer tu web.</h3>
            <p>En Click, realizamos auditoría web exhaustivas para asegurar que tu sitio web esté optimizado, seguro y cumpla con los estándares más exigentes del entorno digital. Una auditoría web es clave para identificar problemas ocultos que pueden afectar el rendimiento, la seguridad y la experiencia de usuario de tu plataforma en línea. Nuestro objetivo es proporcionarte una visión completa de tu sitio web y ayudarte a implementar mejoras que impulsen su eficiencia y efectividad.</p>
            <p>No solo identificamos los problemas, sino que también ofrecemos soluciones personalizadas para optimizar el rendimiento de tu sitio, mejorar la experiencia del usuario y garantizar su seguridad. Con nuestra auditoría web, tu empresa estará preparada para ofrecer un servicio en línea sólido, confiable y competitivo.</p>
          </Col>
        </Row>

        <Row>
          <Col className='my-2 text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 style={{marginTop:'40px',marginBottom:'40px'}}>"Internet no permanece quieto,tu página web tampoco."</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>Contenido la de auditoria web.</h3>
            <p>Nuestras auditorías abarcan varios aspectos fundamentales:</p>
              <p>Rendimiento: Analizamos la velocidad de carga de tu web, la eficiencia de los servidores y la optimización del código para mejorar el tiempo de respuesta y la experiencia del usuario. Un sitio rápido no solo retiene más visitantes, sino que también mejora su posicionamiento en motores de búsqueda.</p>
              <p>SEO: Revisamos tu estructura de SEO para asegurarnos de que tu web cumpla con las mejores prácticas, facilitando su visibilidad y crecimiento en los resultados de búsqueda.</p>
              <p>Seguridad: Identificamos vulnerabilidades y riesgos de seguridad que puedan poner en peligro tu sitio y los datos de tus usuarios. Implementamos medidas de protección avanzadas para prevenir ataques y mantener la integridad de tu web.</p>
              <p>Usabilidad y accesibilidad: Evaluamos la experiencia del usuario, asegurándonos de que tu sitio sea fácil de navegar y accesible para todos los públicos, cumpliendo con normativas de accesibilidad web.</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image className='imgsize' src='./images/auditorias-web2.webp' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             