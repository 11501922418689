import React from 'react';
import { Container,Row,Col,Image } from "react-bootstrap";
import "./cajas.scss";


export default function Cajas() {




  const sizeScreen = (window.screen.width < 570) ? true : false;

  return (
    <>
        <Container className='my-5'>
            <Row>
                <Col>
                    <h2 className='text-center mt-5 mb-5'>Elaboración del producto</h2>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col className='col-12 col-md-6 col-lg-4 my-5'>
                    <div className='card'>
                        <h6>Diseño</h6>
                        <ul className='mt-2' style={{listStyle:'none'}}>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Estudio de viabilidad</span>
                            </li>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Extracción de requisitos</span>
                            </li>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Documentación UML</span>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col className='col-12 col-md-6 col-lg-4 my-5'>
                    <div className='card'>
                        <h6>Desarrollo</h6>
                        <ul className='mt-2' style={{listStyle:'none'}}>
                        <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Metodología ágil</span>
                            </li>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Codificación iterativa</span>
                            </li>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Arquitectura clt/serv</span>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col className='col-12 col-md-6 col-lg-4 my-5'>
                    <div className='card'>
                        <h6>Producción</h6>
                        <ul className='mt-2' style={{listStyle:'none'}}>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Pruebas y testeo</span>
                            </li>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Versionado</span>
                            </li>
                            <li>
                                <Image src='./images/check.png' style={{width:'15px'}} fluid/>
                                <span style={{marginLeft:'5px'}}>Evolutivo y mantenimiento</span>
                            </li>

                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        (sizeScreen) ?
                            <Image src={ '../../images/infografia2.jpg' } fluid style={{marginLeft:'5%',width:'90%'}}/> 
                        :
                            <Image src={ './images/infografia1.jpg' } fluid style={{marginLeft:'5%',width:'90%'}}/>
                    }
                    
                    
                </Col>
            </Row>
        </Container>
    </>
  )
}