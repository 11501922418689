import './tech.scss';
import React from 'react';
import { Container,Row,Col } from "react-bootstrap";


export default function Tech() {
  return (
    <>
        <Container fluid className='fondoTech'>
            <Row>
                <Col className='my-5'>
                    <h2 className='text-center' style={{color:'#00d448'}}>Tecnologías</h2>
                </Col>
            </Row>
            <Row>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'90px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/angular.png' style={{height:'90px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white'>Angular. El framework más potente para aplicaciones web complejas.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'90px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/React.png' style={{height:'75px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>React. Toda la versatilidad de Javascript en programación orientada a componentes.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'90px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/jquery.png' style={{height:'90px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white'>Jquery. La librería para Javacript que ha simplificado el código desde hace décadas.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'90px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/ionic.png' style={{height:'80px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>Ionic. Construye aplicaciones híbridas para android e IOS.</p>
                            </div>
                        </div>
                    </a>
                </div>
            </Row>
            <Row>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'90px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/cloud.png' style={{height:'80px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>Google Cloud. La nube de google ofrece multitud de servicios y APIs.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'142px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/node.png' style={{height:'90px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white'>Node. El entorno de ejecución que lleva Javascript a otro nivel.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'142px',margin:'auto',marginTop:'20px'}}>
                                <img src='./images/logos/php.png' style={{height:'60px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-3'>PHP. Lenguaje diseñado para programar del lado del servidor.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'80px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/laravel.png' style={{height:'80px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>Laravel. El framework más usado de php que cuenta con una gran comunidad.</p>
                            </div>
                        </div>
                    </a>
                </div>
            </Row>
            <Row>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'80px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/python.png' style={{height:'80px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>Python. La versatilidad de Python permite construir servidores, microservicios, etc.</p>
                            </div>
                        </div>
                    </a>  
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'142px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/mysql.png' style={{height:'80px',}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>MySQL. Estandar en las bases de datos de tipo relacional.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'90px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/mongo.png' style={{height:'90px',}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>Mongo. Base de datos no relacional flexible y de código abierto.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-12 col-md-4 col-lg-3 p-4'>
                    <a href={{}}>
                        <div className='cajasTech'>
                            <div style={{width:'90px',margin:'auto',marginTop:'15px'}}>
                                <img src='./images/logos/velneo.png' style={{height:'90px'}}/>
                            </div>
                            <div>
                                <p className='text-center text-white mt-2'>Velneo. Plataforma low-code para el desarrollo de aplicaciones escritorio.</p>
                            </div>
                        </div>
                    </a>
                </div>
            </Row>
        </Container>
    </>
  );
}