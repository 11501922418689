import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./about.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';



export default function About() {
  return (
    <BasicLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>El equipo</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/about" />
                <meta name="description" content="El equipo de Click lo conforman los mejores profesionales del sector de las telecomunicaciones y la informática."></meta>
                <meta name="robots" content="noindex,nofollow"></meta>
            </Helmet>
        <Container className='contenedor'>
          <Row>
            <Col>
              <h1 className='text-center my-5' style={{color:'#131a24'}}>El mejor equipo posible</h1>
            </Col>
          </Row>
          <Row>
            <div className='col-12 col-md-6 my-5'>
              <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/juanjo.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Juan José Castro</h3>
                  <i>CEO & Founder</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/marcos.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Marcos Antonio Agudo</h3>
                  <i>Product Manager</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/angela.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Ángela Gaspar</h3>
                  <i>Directora de operaciones</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/angeles.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Angelines Pérez</h3>
                  <i>Desarrolladora low-code</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/julian.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Julián Tranca</h3>
                  <i>Desarrollador low-code</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/aaron.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Aarón Fernández</h3>
                  <i>Programador Front-end</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/andres.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Andrés Catalina</h3>
                  <i>Analista de sistemas y programador Back-end</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/mario.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Mario Castro</h3>
                  <i>Community Manager</i>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/manuel.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Manuel López</h3>
                  <i>Ingeniero AI IOT y profesor en la UPC</i>
                </div>

              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/genesis.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Génesis Márquez</h3>
                  <i>Redes y diseño corporativo</i>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-6 my-5'>
            <div className='tarjeta' style={{position:'relative'}}>
                <Image fluid src='./images/luis.webp' className='imageProfile'/>
                <Image fluid src='./images/velo.png' style={{position:'absolute',top:'0',left:'0',height:'380px'}} className='imageProfile'/>
                <div className='text-center'>
                  <h3>Luis</h3>
                  <i>El del café de los jueves</i>
                </div>

              </div>
            </div>

           
          </Row>

          <Row>
            <Col className='col-12 col-md-12 mt-3 mb-5'>
              <h3>Nuestro equipo: El corazón de nuestra empresa</h3>
              <p>En Click, creemos firmemente que el éxito está impulsado por el talento, la dedicación y la pasión de nuestro equipo. Cada uno de nuestros trabajadores es una pieza clave en la maquinaria que nos permite crecer, innovar y ofrecer el mejor servicio a nuestros clientes.</p>
              <p>Nos enorgullece contar con profesionales comprometidos, que no solo dominan sus áreas, sino que también comparten nuestros valores de excelencia, colaboración y responsabilidad. Su esfuerzo diario y su espíritu de equipo constituye el motor que nos lleva a superar cada desafío y alcanzar nuevos horizontes.</p>
              <p>A todos ellos, ¡gracias por ser el alma de esta empresa!</p>
            </Col>
          </Row>
        </Container>
    </BasicLayout>
  )
}