import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./scss/casos.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';



export default function Auditoriasprotecciondatos() {
  return (
    
    <BasicLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Auditorias de protección de datos</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/auditorias-proteccion-datos" />
                <meta name="description" content="Garantiza el cumplimiento normativo de tu negocio digital. Las auditorias de protección de datos te ayudan a conocer las fortalezas y debilidades de la forma en que tratas con datos personales."></meta>
            </Helmet>
      <Container fluid>

        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >Auditorias de proteccion de datos</h1>
          </Col>
        </Row>
        
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='./images/proteccion-datos.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>Protección de datos</h3>
            <p>En Click, entendemos que la protección de datos es esencial en un entorno digital donde la privacidad y la seguridad son una prioridad para las empresas y los clientes. Ofrecemos auditorías de protección de datos para asegurar que tu organización cumpla con las normativas vigentes, como el Reglamento General de Protección de Datos (RGPD) y otras leyes de privacidad aplicables.</p>
            <p>Nuestra auditoría de protección de datos está diseñada para evaluar en profundidad tus políticas, procesos y sistemas, identificando posibles vulnerabilidades que puedan comprometer la integridad y seguridad de la información que manejas. Analizamos cómo tu empresa recopila, almacena, procesa y protege los datos personales, asegurándonos de que cumplas con todos los requisitos legales y mejorando la confianza de tus clientes.</p>
            <p>¿Necesitas un delegado de protección de datos?¿Qué diferencia hay entre responsable y encargado de tratamiento? Nuestro equipo te ayudatá con las dudas y te prestará el asesoramiento que precises.</p>
          </Col>
        </Row>

        <Row>
          <Col className='my-2 text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 style={{marginTop:'40px',marginBottom:'40px'}}>"La protección de la información en sistemas digitales es el gran campo de batalla de la ciberseguridad"</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>Contenido de auditoría de protección de datos.</h3>
            <p>Los aspectos clave que evaluamos incluyen:</p>
              <p>Cumplimiento normativo: Verificamos si tu empresa cumple con las leyes de protección de datos vigentes, tanto a nivel local como internacional, y si tienes implementadas las políticas adecuadas para salvaguardar la privacidad de los usuarios.</p>
              <p>Seguridad de los datos: Identificamos vulnerabilidades en los sistemas y procesos que podrían ser explotadas por atacantes, asegurando que los datos sensibles estén protegidos contra accesos no autorizados, filtraciones o robos.</p>
              <p>Gestión de riesgos: Evaluamos los procedimientos de respuesta ante incidentes, y te ayudamos a implementar planes de acción en caso de brechas de seguridad, minimizando el impacto y cumpliendo con los requisitos de notificación.</p>
            <p>En Click, nuestras auditorías no solo identifican los problemas, sino que también te proporcionan un plan de acción para garantizar que tu empresa esté completamente protegida y en pleno cumplimiento legal, salvaguardando tanto tus activos como la confianza de tus clientes.</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image className='imgsize' src='./images/proteccion-datos2.webp' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             