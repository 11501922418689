import React from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import BasicLayout from '../../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';




export default function AlianzaAnubisclick() {

    var publicacion =  {
      "title":"Alianza entre Anubis Analytics y Click por la ciberseguridad.",
      "fch_publish":"18 de noviembre de 2022",
      "alt_img":"alianza anubis-click",
      "img":"../../images-blog/anubis-click.webp",
    };


    return (
      <BasicLayout>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Alianza Anubis/Click</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/blog/alianza-anubis-click" />
                <meta name="description" content="Anubis Analytics y Click sellan una alianza para lanzar nuevas aplicaciones enfocadas en el área sociosanitaria."></meta>
            </Helmet>
          <Container fluid>
            <Row className='my-5'>
              <Col>
                <h1 style={{color:'#29363b'}} className='text-center'>{publicacion.title}</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={9}>
                <Container fluid>
                    <Row className='mb-3'>
                      <Col><span style={{fontSize:'.8em'}}>Fecha de publicación: <strong>{publicacion.fch_publish}</strong></span><span style={{fontSize:'.8em'}}>, Autor: <strong>{publicacion.author}</strong></span></Col>
                      <Col className='text-end'></Col>
                    </Row>
                    <Row className='justify-content-center my-4'>
                        <Image style={{width:'500px'}} src={publicacion.img} fluid/>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <p>Anubis Analytics y Click aplicaciones sellan su alianza para lanzar un nuevo software. Si te interesa esta noticia, puedes leerla <a href="https://balancesociosanitario.com/actualidad/alianza-click-aplicaciones-y-anubis-analytics-por-la-ciberseguridad/">aquí</a>. Si quieres conocer el Proyecto Anubis-Click con la Fundación Edad y Vida haz click <a href='/proyectoedad'>aquí</a>.</p>
                      </Col>
                    </Row>
                   
                </Container>
              </Col>
            </Row>
          </Container>
      </BasicLayout>
    )
}