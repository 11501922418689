import React from 'react';
import "./BasicLayout.scss";
import  Menu  from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import CookieConsent, { Cookies } from "react-cookie-consent";


export default function BasicLayout(props) {
    const { children } = props;
    //console.log(props);

  return (
            <>
                    <Menu/>
                      { children }
                    <Footer/>
                    <CookieConsent
                        location="bottom"
                        buttonText="Acepto"
                        cookieName="aceptcookies"
                        style={{ background: "#2B373B" }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        expires={150}
                        onAccept={() => {}}
                        enableDeclineButton
                        declineButtonText="Denegar"
                        onDecline={() => {}}>
                        Este sitio web usa cookies para mejorar la experiencia del usuario.{" "}
                    </CookieConsent>
            </>
          )
}