import React, {useState} from 'react'
import { Container,Row,Col, Image } from"react-bootstrap";
import Contactform from '../components/Contactform/contactform';
import HTMLFlipBook from 'react-pageflip';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';



export default function Contact(props) {

    //const {props} = props;
    //const state = {value: ''};
    const [state,setState] = useState("");

  function handleChange(event) {
    setState({value: event.target.value});
  }

  function handleSubmit(event) {
    alert('A name was submitted: ' + state.value);
    event.preventDefault();
  }

  const sizeScreen = (window.screen.width < 570) ? true : false;


  return (
    <>
        <BasicLayout>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Contacto</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/contact" />
                <meta name="description" content="Contacta con nosotros y te asesoraremos sin compromiso sobre nuestro productos y servicios."></meta>
            </Helmet>
          <Container className='mt-5'>
            <Row>
              <Col>
                <h1>Contacto</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} className='p-1 p-md-3 p-lg-5'>
                <p>Ya puedes contactar con Jurista Digital. Si tienes alguna pregunta, necesitas información, o quieres dejarnos un comentario, puedes usar el siguiente formulario.</p>
                <ul>
                  <li>Responsable de los datos: Click Aplicaciones S.L.</li>
                  <li>Finalidad: Contactar con la persona interesada para resolver el motivo por el que se pone en contacto. Incluir al contacto en lista de comunicados.</li>
                  <li>Legitimación: Consentimiento del interesado.</li>
                  <li>Destinatarios: Encargados del tratamiento de tus datos: Click Aplicaciones S.L.</li>
                  <li>Derechos: Acceder, rectificar y suprimir los datos, y otros derechos, como se explica en nuestra política de privacidad.</li>
                </ul>
              </Col>
              <Col xs={12} md={8} className='my-5'>

                <Contactform/>
              </Col>
            </Row>
           
           
          </Container>  
          <Container fluid className='fondo_oscuro'>
            <Row className='justify-content-center' style={{marginBottom:'100px',width:'100%'}}>
              <h6 className='text-center mb-5'>Dossier de prensa</h6>
              {
                sizeScreen ?
                <HTMLFlipBook width={300} height={300}>
                    <div ><Image src='./images/dossier_pag1.jpg' style={{width:'300px'}}/></div>
                    <div ><Image src='./images/dossier_pag2.jpg' style={{width:'300px'}}/></div>
                    <div ><Image src='./images/dossier_pag3.jpg' style={{width:'300px'}}/></div>
                    <div ><Image src='./images/dossier_pag4.jpg' style={{width:'300px'}}/></div>
                </HTMLFlipBook>
                :

                <HTMLFlipBook width={500} height={500}>
                    <div ><Image src='./images/dossier_pag1.jpg' style={{width:'500px'}}/></div>
                    <div ><Image src='./images/dossier_pag2.jpg' style={{width:'500px'}}/></div>
                    <div ><Image src='./images/dossier_pag3.jpg' style={{width:'500px'}}/></div>
                    <div ><Image src='./images/dossier_pag4.jpg' style={{width:'500px'}}/></div>
                </HTMLFlipBook>
              }

            </Row>  
          </Container>  
          <Container>
            <Row className='mb-5 py-5'>
              <Col className='col-12 col-md-6'>
                  <h3>Atención personalizada</h3>
                  <p>Contactar con Click es muy fácil. Solo tienes que rellenar el formulario y hacer click en el botón enviar.</p>
                  <p>Atención personalizada por profesionales que han desarrollado su vida profesional en el sector de las telecomunicaciones y la informática.</p>
                  <p>Del mismo modo, si en algún momento deseas contribuir de forma activa al crecimiento de la plataforma, estaremos encantados de conocer y valorar cualquier propuesta encaminada a mejorar nuestra empresa.</p>
                  <p>La plataforma esta abierta a todo tipo de colaboración siempre y cuando sea positiva y reporte valor en nuestro sector o en la digitalización de las empresas y profesionales.</p>
              </Col>
              <Col className='col-12 col-md-6'>
                  <Image fluid src="./images/mapa.jpg" alt="Atención personalizada" />
              </Col>
            </Row>
          </Container>       
        </BasicLayout>
    </>
    
  )
}
