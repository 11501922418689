import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./scss/casos.scss";
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';


export default function Centrix() {
  return (
    
    <BasicLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Centrix</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/cetrix" />
                <meta name="description" content="Centrix es la aplicación propiedad de Más Orange que se ocupa de la gestión de las subestaciones de telefonía."></meta>
            </Helmet>
      <Container fluid>

        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1 >Centrix</h1>
          </Col>
        </Row>
        
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='./images/hombre-luces.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>¿Qué es Centrix?</h3>
            <p>Centrix nace en el año 2005 con el objetivo de dar solución a los problemas de gestión en las subestaciones de telefonía. </p>
            <p>En 2010 La multinacional Orange, hoy Más Orange, se hace con la propiedad de la herramienta, de cuyo mantenimiento y evolución se sigue ocupando el equipo de desarrollo de Click Aplicaciones.</p>
            <p>Centrix cuenta con numerosas funcionalidades y más de quince módulos enfocados a cubrir todos los requerimientos. Desde accesos: registro y solicitudes, mantenimiento o preventivos, presupuestos hasta la gestión de los propios usuarios y técnicos.</p>
            <p>La aplicación es multiplataforma. Se han desarrollado tres versiones para garantizar la comodidad de los usuarios. La versión de escritorio es la más adecuada para el trabajo en oficina. La versión móvil es la preferida por los técnicos que tienen registrar su actividad mientras se desplazan. Pero también existe una potente versión web cien por cien “responsive” que ofrece toda la versatilidad necesaria sin perder un ápice de eficiencia.</p>
          </Col>
        </Row>

        <Row>
          <Col className='my-2 text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 className='my-4'>"Centrix, la herramienta multiplataforma para gestionar las subestaciones de telefonía"</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>¿Qué soluciona Centrix?</h3>
            <p>Los trabajos que realiza una operadora de telefonía en una subestación de Telefonía son de extrema importancia. Una mala praxis o una negligencia puede derivar en la interrupción del servicio de numerosos usuarios de la red, o el daño en los equipos de transmisión y recepción.</p>
            <p>Por todo ello, es necesario que cada acción o planificación que se vaya a realizar esté debidamente registrada y gestionada, estableciendo una cadena de responsabilidad que asegure los trabajos a realizar.</p>
            <p>Centrix, además de su gran número de funcionalidades, cuenta con un servicio técnico reactivo y personalizado, capaz de dar una respuesta rápida a los problemas que puedan surgir.</p>
            <p>Cada uno de sus módulos cubre una función o necesidad, pero todos ellos se caracterizan por ser tremendamente intuitivos, y cada uno de ellos cuenta con una extensa documentación y guía de funcionamiento.</p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image className='imgsize' src='./images/chinchetas.webp' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             