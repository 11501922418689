import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import { App } from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Contact from './pages/contact';
import Mision from './pages/mision';
import Trabaja from './pages/trabaja-con-nosotros';
import Appweb from './pages/appweb';
import Gestionempresas from './pages/gestion-empresas';
import Adaptanegocio from './pages/adapta-negocio-normativa';
import Auditoriasweb from './pages/auditorias-web';
import Auditoriasprotecciondatos from './pages/auditorias-proteccion-datos';
import Centrix from './pages/centrix';
import Talex from './pages/talex';
import Erpclick from './pages/erpclick';
import Proyectoedad from './pages/proyectoedad';
import Blog from './pages/blog';
import AlianzaAnubisclick from './pages/blog/alianza-anubis-click';
import ChatGPT from './pages/blog/chatgpt-y-la-inteligencia-artificial';
import VivInvNudaProp from './pages/blog/vivienda-inversa-vs-nuda-propiedad-inconvenientes-y-ventajas';
import AvisoLegal from './pages/aviso-legal';
import PoliticaPrivacidad from './pages/politica-de-privacidad';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
        <Route path="/" element={ <App /> }></Route>
        <Route path="/about" element={ <About /> }></Route>
        <Route path="/mision" element={ <Mision /> }></Route>
        <Route path="/trabaja-con-nosotros" element={ <Trabaja /> }></Route>
        <Route path="/appweb" element={ <Appweb /> }></Route>
        <Route path="/gestion-empresas" element={ <Gestionempresas /> }></Route>
        <Route path="/adapta-negocio-normativa" element={ <Adaptanegocio /> }></Route>
        <Route path="/auditorias-web" element={ <Auditoriasweb /> }></Route>
        <Route path="/auditorias-proteccion-datos" element={ <Auditoriasprotecciondatos /> }></Route>
        <Route path="/centrix" element={ <Centrix /> }></Route>
        <Route path="/talex" element={ <Talex /> }></Route>
        <Route path="/erpclick" element={ <Erpclick /> }></Route>
        <Route path="/proyectoedad" element={ <Proyectoedad /> }></Route>
        <Route path="/blog" element={ <Blog /> }></Route>
        <Route path="/blog/alianza-anubis-click" element={ <AlianzaAnubisclick /> }></Route>
        <Route path="/blog/chatgpt-y-la-inteligencia-artificial" element={ <ChatGPT /> }></Route>
        <Route path="/blog/vivienda-inversa-vs-nuda-propiedad-inconvenientes-y-ventajas" element={ <VivInvNudaProp /> }></Route>
        <Route path="/contact" element={ <Contact /> }></Route>
        <Route path="/aviso-legal" element={ <AvisoLegal /> }></Route>
        <Route path="/politica-privacidad" element={ <PoliticaPrivacidad /> }></Route>
        <Route path="/contact" element={ <Contact /> }></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
