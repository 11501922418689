import React from 'react';
import "./Jumbotron.scss";

export default function Jumbotron() {
  var percentage = '25%';
  var ancho = "50%";
  if(window.screen.width < 500){
    percentage = '5%';
    ancho = '90%';
  } 
  return (
    <>
      <div className='jumbotron'>
          <div className='container'>
              <div className='row'>
                  <div className='col-12 col-md-6'>
                  <div className='text-center'>
                      <h6 className='mb-3' style={{marginTop:'80px',color:'#00d448'}}>Software development</h6>
                      {/* <h1 className='my-5'>Click. La más novedosa tecnología ahora al alcance de tu empresa.</h1> */}
                      <h1 className='my-5'>Ya trabajamos con las principales operadoras de telefonía en España.</h1>
                    </div>
                  </div>
                  {/* <div className='d-none d-md-block offset-md-1'></div> */}
                  <div className='col-12 col-md-6'>
                  
                  </div>
              </div>
          </div>
      </div>
    </>
    
  )
}
