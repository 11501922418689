import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import { Helmet } from 'react-helmet';

export default function Talex() {
  return (
    
    <BasicLayout>

      <Container fluid>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Centrix</title>
                <link rel="canonical" href="https://www.clickaplicaciones.com/talex" />
                <meta name="description" content="Talex es la aplicación lider en la gestión de las subestaciones de telefonía."></meta>
            </Helmet>
        <Row>
          <Col className='text-center mt-5 colorTitulo'>
            <h1>Talex</h1>
          </Col>
        </Row>
        
        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <div className='text-center  '>
              <Image className='imgsize' src='./images/chip.webp' fluid/>
            </div>
          </Col>
          <Col className='col-12 col-md-6'>
            <h3>¿Qué es Talex?</h3>
            <p>Talex es la aplicación integral de gestión de subestaciones de telefonía. Desarrollada, mantenida y evolucionada por Click para las necesidades de Más Móvil, red gestionada por Ericsson y Euskaltel. La potencia de esta aplicación radica en los potentes módulos que la componen.</p>
            <p>Talex nace en el año 2017 y en 2018 Ericsson adquiere la propiedad de la herramienta que en 2024 continúa funcionando plenamente.</p>
            <p>Junto al registro y las solicitudes de acceso, la gestión de los mantenimientos, presupuestos, KPI, etc.</p>
          </Col>
        </Row>

        <Row>
          <Col className='my-2 text-center comillas'>
              <div className='p-1 p-md-3'>
                <h3 className='my-4'>"Talex, la herramienta para gestionar los trabajos subestaciones de telefonía"</h3>
              </div>
          </Col>
        </Row> 

        <Row className='my-5 p-1 p-md-5'>
          <Col className='col-12 col-md-6'>
            <h3>Change Management</h3>
            <p>El módulo más potente. Gestiona los trabajos programados de los diferentes nodos que puedan requerir cualquier tipo de mantenimiento o actualización.</p>
            <p>Desarrollado con tecnologías reactivas, arquitectura cliente/servidor y base de datos en tiempo real. El resultado es que a pesar de las numerosas funcionalidades del módulo y los complejos cálculos que en ocasiones tiene que realizar, no supone una merma en la velocidad ni en su eficiencia. </p>
          </Col>
          <Col className='col-12 col-md-6' >
            <div className='text-center'>
              <Image className='imgsize' src='./images/mujer-tablet.webp' fluid/>
            </div>
          </Col>
        </Row>

      </Container>
    </BasicLayout>
  );
}
             